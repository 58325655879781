import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useRouter } from 'next/router'

import { store } from 'redux/store'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'
import { generateIsLarge, generateIsMidsized, generateIsMobile } from 'redux/active/initialState'

import GlobalToolbar from 'components/misc/GlobalToolbar'
import Headers from 'components/misc/Headers'
import Footer from 'components/misc/Footer'
import NewDeckModal from 'components/misc/NewDeckModal'
import ToastContainer from 'components/toasts/ToastContainer'
import SearchV2 from 'components/cardSearchPanel/searchV2'
import ScrollZone from 'components/deckPage/dragAndDrop/ScrollZone'
import FixedAd from 'components/advertisments/FixedAd'
import { CardModal } from 'components/card/CardModal'

import { DEFAULT_DESCRIPTION } from 'utils/DefaultDescription'

import styles from './pageWrapper.module.scss'

type Props = {
  title?: string | null
  description?: string
  robots?: string
  rightContent?: any
  noFooter?: boolean
  lockable?: boolean
  children?: React.ReactNode
  dndPage?: boolean
  noSearch?: boolean
  className?: string
  noScroll?: boolean
}

/**
 * This component is called within all page components. It renders the global toolbar, the sidebars, the body,
 * the footer, the metadata with React-Helmet and listens to the page width to detect mobile.
 */
const PageWrapper = ({
  title,
  description,
  robots,
  noFooter,
  children,
  lockable,
  className = '',
  noSearch,
  dndPage,
}: Props) => {
  const router = useRouter()

  const updateDimensions = () => {
    const isMobile = generateIsMobile()
    const isMidsize = generateIsMidsized()
    const isLarge = generateIsLarge()

    store.dispatch({ type: SET_ACTIVE_STATE, payload: { isMobile, isMidsize, isLarge } })
  }

  useEffect(() => {
    const referredBy = router.query.referrer || null

    if (referredBy) ReactGA.event({ category: 'Tracking', action: `ReferredBy: ${referredBy}` })

    updateDimensions()

    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const pageStyles: React.CSSProperties = {}

  if (lockable) pageStyles.display = 'flex'

  return (
    <>
      <GlobalToolbar noSearch={noSearch} />

      <div style={pageStyles}>
        <SearchV2 lockable={lockable} />

        <div className={styles.content}>
          {dndPage && <ScrollZone />}
          <main className={`${styles.main} ${className}`}>{children}</main>

          {!noFooter && <Footer />}
          {dndPage && <ScrollZone bottom />}
        </div>
      </div>

      {/* Globally loded modals. We should probably move at least 1 or two of these down a level (or to where they're actually invoked) */}
      <NewDeckModal />
      <CardModal />
      <ToastContainer />

      <FixedAd id="fixed-ad" />

      {/* Intentionally putting headers at the bottom of the <head> */}
      {/* The way NEXT renders headers is by order in which them appear. By pushing the defaults to the bottom, any ad-hoc headers declared in the children of the page wrapper will be the primaries */}
      <Headers description={description ? description : DEFAULT_DESCRIPTION} title={title} robots={robots} />
    </>
  )
}

export default PageWrapper
