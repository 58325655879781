import { GetServerSidePropsContext, NextPageContext } from 'next'
import { AppContext } from 'next/app'

import environment from 'environment'

import { PATREON_TIERS, ROLES } from 'types/user'

import CookieService from 'services/cookie.service'

import { timeInSecondsToEpoch } from 'utils/time.utils'

export const PUBLIC_ROUTES = [
  '/login',
  '/register',
  '/emailConfirmation/[key]',
  '/forgot-password',
  '/passwordReset/[...serializedData]',
  '/connect-patreon',
]

export type ArchidektJwt = {
  user_id: number
  username: string
  email: string
  iat: string // epoch time in seconds
  exp: number // epoch time in seconds
  token_type?: string
}

type RefreshResponse = {
  refresh_token: string
  token: string
  access?: string // new version
}

export function decodeJwt(jwt: string): ArchidektJwt | null {
  const base64Url = jwt.split('.')[1]

  if (!base64Url) return null

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export async function getToken(): Promise<string> {
  const refreshToken = CookieService.get('tbRefresh') || null
  const jwt = CookieService.get('tbJwt') || null

  if (!jwt && !refreshToken) return ''
  if (!jwt && refreshToken) return fetchNewToken(false).then(setToken)

  const decodedToken = decodeJwt(jwt)
  const expirationCutoff = timeInSecondsToEpoch() + 60 // within 60 seconds of expiration

  if (!decodedToken) return fetchNewToken(false).then(setToken)
  if (decodedToken.exp > expirationCutoff) {
    CookieService.set('tbJwt', jwt)

    return jwt
  }

  if (decodedToken.token_type) return fetchNewToken(false).then(setToken)
  return fetchNewToken(true).then(setToken)
}

export async function getTokenFromServerContext(
  serverContext?: GetServerSidePropsContext,
  pageContext?: NextPageContext,
) {
  const jwt = CookieService.get('tbJwt', serverContext?.req.cookies || pageContext?.req?.headers?.cookie)
  const refreshToken = CookieService.get('tbRefresh', serverContext?.req.cookies || pageContext?.req?.headers?.cookie)

  if (!jwt && !refreshToken) return ''
  if (!jwt && refreshToken) return fetchNewToken(false, refreshToken).then(setToken)
  if (!jwt) return

  const decodedToken = decodeJwt(jwt)
  const expirationCutoff = timeInSecondsToEpoch() + 60 // within 10 seconds of expiration

  if (!decodedToken) return fetchNewToken(false, refreshToken).then(setToken)
  if (decodedToken.exp > expirationCutoff) {
    CookieService.set('tbJwt', jwt)

    return jwt
  }

  return fetchNewToken(false, refreshToken).then(setToken)
}

function setToken({ token, access }: RefreshResponse) {
  // TODO should only be access after auth_update
  const jwt = access || token

  CookieService.set('tbJwt', jwt)

  return jwt
}

function fetchNewToken(old: boolean, providedRefreshToken?: string) {
  // TODO only need !old after auth_update
  const refreshToken = providedRefreshToken ? providedRefreshToken : CookieService.get('tbRefresh') || null

  return fetch(`${environment.apiUrl}/api/${old ? 'refresh' : 'rest-auth/token/refresh'}/`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(old ? { client_id: 'archidekt', refresh_token: refreshToken } : { refresh: refreshToken }),
  })
    .then(response => {
      if (!response.ok) throw new Error('Refresh fetch failed')
      return response.json()
    })
    .catch(err => {
      CookieService.logout()

      return ''
    })
}

// Forcibly logs out user if they have a userId and username but their token is expired or otherwise missing
export async function validateServerAuthData({ ctx }: AppContext) {
  const userId = CookieService.get('tbId', ctx?.req?.headers?.cookie)
  const username = CookieService.get('tbUser', ctx?.req?.headers?.cookie)

  if (!userId && !username) return true // the user just isn't logged in, totally fine

  const authData = await getTokenFromServerContext(undefined, ctx)

  if (authData) return true

  CookieService.serverLogout(ctx)

  return false
}

// Returns true if you have access to topdekt or the site as a whole
export function validateTopDektAuth({ ctx, router }: AppContext): string | null {
  if (!environment.runningOnPrivateServer) return null
  if (PUBLIC_ROUTES.includes(router.pathname)) return null

  try {
    const userId = CookieService.get('tbId', ctx?.req?.headers?.cookie) || '0'
    const roles = CookieService.get('tbR', ctx?.req?.headers?.cookie) || []
    const tier = CookieService.get('tbTier', ctx?.req?.headers?.cookie) || 0

    if (environment.modOnly && !roles.includes(ROLES.MOD)) return '/login'

    const hasAccess = roles.includes(ROLES.TESTER) || Number(tier) >= PATREON_TIERS.UNCOMMON

    if (!Number(userId)) return '/login'
    if (!hasAccess) return '/connect-patreon'
  } catch {
    return '/login'
  }

  return null
}

export function checkIfModerator(context: GetServerSidePropsContext) {
  const roles = CookieService.get('tbR', context.req.headers.cookie) || []

  return roles.includes(ROLES.MOD)
}
