import React, { useState, useEffect } from 'react'

import { useAppSelector } from 'redux/hooks'

import Select, { Options } from 'components/formElements/SemanticSelect'

const options: Options[] = [
  { value: 'Basic', text: 'Basic' },
  { value: 'Legendary', text: 'Legendary' },
  { value: 'Ongoing', text: 'Ongoing' },
  { value: 'Snow', text: 'Snow' },
  { value: 'World', text: 'World' },
]

type Props = {
  onChange: (values: string[]) => void
  value?: string | string[]
}

const SuperTypeDropdown = ({ value, onChange }: Props) => {
  const isMobile = useAppSelector(state => state.active.isMobile)

  const [selectedOptions, setSelectedOptions] = useState(value ?? new Array<string>())

  // Used to reset the form
  useEffect(() => {
    if (!value || !value?.length) setSelectedOptions([])
  }, [value])

  const handleChange = (selectedOptions: string[]) => {
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select
      search={!isMobile}
      placeholder="Supertype (eg: Basic)"
      onChange={handleChange}
      options={options}
      value={selectedOptions}
      multiple
    />
  )
}

export default SuperTypeDropdown
