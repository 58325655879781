import React, { useState } from 'react'

import { ColorSearchOptions } from 'services/apiTypes/card.types'

import ManaSymbol from 'components/misc/customIcons/ManaSymbol'

import styles from './colorToggles.module.scss'

type Props = {
  value?: ColorSearchOptions
  onChange: (values: ColorSearchOptions) => void
}

const ColorToggles = ({ value, onChange }: Props) => {
  // Yes, our API is dumb and case matters here 😒
  const [White, setWhite] = useState<boolean>(value?.White ?? true)
  const [Blue, setBlue] = useState<boolean>(value?.Blue ?? true)
  const [Black, setBlack] = useState<boolean>(value?.Black ?? true)
  const [Red, setRed] = useState<boolean>(value?.Red ?? true)
  const [Green, setGreen] = useState<boolean>(value?.Green ?? true)
  const [Colorless, setColorless] = useState<boolean>(value?.Colorless ?? true)

  const handleToggleWhite = () => {
    setWhite(!White)
    handleChange({ White: !value?.White ?? !White })
  }

  const handleToggleBlue = () => {
    setBlue(!Blue)
    handleChange({ Blue: !value?.Blue ?? !Blue })
  }

  const handleToggleBlack = () => {
    setBlack(!Black)
    handleChange({ Black: !value?.Black ?? !Black })
  }

  const handleToggleRed = () => {
    setRed(!Red)
    handleChange({ Red: !value?.Red ?? !Red })
  }

  const handleToggleGreen = () => {
    setGreen(!Green)
    handleChange({ Green: !value?.Green ?? !Green })
  }

  const handleToggleColorless = () => {
    setColorless(!Colorless)
    handleChange({ Colorless: !value?.Colorless ?? !Colorless })
  }

  const handleChange = (updates: Partial<ColorSearchOptions>) => {
    onChange({
      White: value?.White ?? White,
      Blue: value?.Blue ?? Blue,
      Black: value?.Black ?? Black,
      Red: value?.Red ?? Red,
      Green: value?.Green ?? Green,
      Colorless: value?.Colorless ?? Colorless,

      ...updates,
    })
  }

  return (
    <div className={styles.container}>
      <button type="button" onClick={handleToggleWhite} style={{ opacity: value?.White ?? White ? '1' : '0.2' }}>
        <ManaSymbol symbol="W" height="2em" width="2em" />
      </button>
      <button type="button" onClick={handleToggleBlue} style={{ opacity: value?.Blue ?? Blue ? '1' : '0.2' }}>
        <ManaSymbol symbol="U" height="2em" width="2em" />
      </button>
      <button type="button" onClick={handleToggleBlack} style={{ opacity: value?.Black ?? Black ? '1' : '0.2' }}>
        <ManaSymbol symbol="B" height="2em" width="2em" />
      </button>
      <button type="button" onClick={handleToggleRed} style={{ opacity: value?.Red ?? Red ? '1' : '0.2' }}>
        <ManaSymbol symbol="R" height="2em" width="2em" />
      </button>
      <button type="button" onClick={handleToggleGreen} style={{ opacity: value?.Green ?? Green ? '1' : '0.2' }}>
        <ManaSymbol symbol="G" height="2em" width="2em" />
      </button>

      <button
        type="button"
        onClick={handleToggleColorless}
        style={{ opacity: value?.Colorless ?? Colorless ? '1' : '0.2' }}>
        <ManaSymbol symbol="C" height="2em" width="2em" />
      </button>
    </div>
  )
}

export default ColorToggles

type RadioProps = {
  value?: boolean
  onChange: (value: boolean) => void
}

export const ColorAndOrRadio = ({ value, onChange }: RadioProps) => {
  const [localValue, setLocalValue] = useState<boolean>(value ?? false)

  const orLogic = 'or-logic'
  const andLogic = 'and-logic'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value === andLogic)
    onChange(e.target.value === andLogic)
  }
  return (
    <div className={styles.radioContainer}>
      <label className={styles.mainRadioLabel}>Logic:</label>
      <span className={styles.cursors}>
        <input
          onChange={handleChange}
          name={orLogic}
          value={orLogic}
          type="radio"
          id={orLogic}
          checked={!value ?? !localValue}
        />
        <label htmlFor={orLogic}>Or</label>
        <input
          onChange={handleChange}
          name={andLogic}
          value={andLogic}
          type="radio"
          id={andLogic}
          checked={value ?? localValue}
        />
        <label htmlFor={andLogic}>And</label>
      </span>
    </div>
  )
}

export const ColorIdentityRadio = ({ value, onChange }: RadioProps) => {
  const [localValue, setLocalValue] = useState<boolean>(value ?? false)

  const colorName = 'logic-color'
  const identityName = 'identity-color'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value === identityName)
    onChange(e.target.value === identityName)
  }

  return (
    <div className={styles.radioContainer}>
      <label className={styles.mainRadioLabel}>Options:</label>
      <span className={styles.cursors}>
        <input
          onChange={handleChange}
          name={colorName}
          value={colorName}
          type="radio"
          id={colorName}
          checked={!value ?? !localValue}
        />
        <label htmlFor={colorName}>Color</label>
        <input
          onChange={handleChange}
          name={identityName}
          value={identityName}
          type="radio"
          id={identityName}
          checked={value ?? localValue}
        />
        <label htmlFor={identityName}>Identity</label>
      </span>
    </div>
  )
}
