const tagUrlAliases: Record<string, string> = {
  '+1/+1 Counters': 'plus-counters',
  '-1/-1 Counters': 'minus-counters',
  'Tap/Untap': 'tap-untap',
}

export const tagNameToUrl = (tagName: string) => {
  if (tagUrlAliases[tagName]) return tagUrlAliases[tagName]

  return tagName.replace(/ /g, '_').toLocaleLowerCase()
}

export const urlToTag = (slug: string) => {
  const reversedAliases = Object.fromEntries(Object.entries(tagUrlAliases).map(([k, v]) => [v, k]))

  if (reversedAliases[slug]) return encodeURIComponent(reversedAliases[slug])

  return encodeURIComponent(slug.replace(/_/g, ' '))
}
