import { useState, useEffect } from 'react'
import { invert } from 'lodash'
import { createSelector } from 'reselect'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import CookieService from 'services/cookie.service'
import { getBooleanFlagFromLocalStorage } from 'services/accountSettings.service'

import type { RootState } from '../reducers'

import {
  CardType,
  CategoryType,
  defaultCard,
  EDH_FORMATS,
  HUMAN_OWNED,
  NUMERIC_STACKS,
  SortOptionType,
  STACK,
  StackOptionType,
  StackType,
  TIME_STACKS,
  VIEW,
  ViewType,
} from 'types/deck'
import { getCardCMC, getCmcForSpellsOnly } from 'utils/GetCardCMC'
import { getPrice } from 'utils/Price'

import { selectFilteredCards, selectPriceSource, selectIsMobile } from '../active/selectors'
import { RARITY_WEIGHTS, RarityOptionType } from 'types/card'
import { orderByNumericStackHeader, SortFunctionMap } from 'redux/deck/helpers/sorting'
import { getSaltRange, getPriceRange, getColors, getTimeFrames, TIME_RANGES } from './helpers/stackRanges'
import { checkCanEdit } from 'utils/deckPermissionHelpers'

import { selectDeck } from '../reducers'

import { useAppSelector } from 'redux/hooks'

import { getIdentityNameOrdering } from 'utils/colorUtils'
import { rightCommanderKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'

import {
  selectShowEmptyCardStacks,
  selectHideDefaultTypeCategories,
  selectHideOutOfDeckCategories,
} from 'redux/cacheables/selectors'

// const selectDeck = (state: any) => state.deck

export const selectCategories = createSelector(selectDeck, deckState => deckState.categories)
export const selectCardMap = createSelector(selectDeck, deckState => deckState.cardMap)
export const selectSorting = createSelector(selectDeck, deckState => deckState.sorting)
export const selectStackType = createSelector(selectDeck, deckState => deckState.stack)
export const selectViewTypes = createSelector(selectDeck, deckState => deckState.view)
export const selectMenuCardId = createSelector(selectDeck, deckState => deckState.menuCardId)
export const selectMenuCardToEditions = createSelector(selectDeck, deckState => deckState.openMenuCardToEditions)

export const selectSaltSum = createSelector([selectCardMap, selectCategories], (cardMap, categories) => {
  let sum = 0
  for (let cardId in cardMap) {
    const card = cardMap[cardId]
    if (categories[card.categories[0]]?.includedInDeck && card.salt) {
      sum += card.salt * card.qty
    }
  }
  return parseFloat(sum.toFixed(2))
})

export const selectCardById = createSelector(
  selectCardMap,
  (_state: RootState, id: string) => id,
  (cardMap, id) => {
    if (cardMap[id]) return cardMap[id]
    return defaultCard
  },
)

// Only used in a Saga
export const selectDeckRecsData = (state: RootState) => {
  let commander = ''
  if (EDH_FORMATS.includes(state.deck.format)) {
    let commanderNames: Array<string> = []
    selectCommanders(state).forEach(id => {
      commanderNames.push(state.deck.cardMap[id].name)
    })
    commander = commanderNames.join('_')
  }
  return {
    cardMap: state.deck.cardMap,
    commander: commander,
  }
}

// Only used in a Saga
export const selectEDHRecsData = (state: RootState) => {
  return {
    cardMap: state.deck.cardMap,
    commanders: selectCommanders(state),
  }
}

export const selectUsedCategories = createSelector(selectCardMap, selectCategories, (cardMap, categories) => {
  const usedCategories: Record<string, boolean> = {}

  const categoriesOnCards = Object.values(cardMap).reduce(
    (acc, card) => [...acc, ...card.categories],
    new Array<string>(),
  )

  for (const category of categoriesOnCards) {
    usedCategories[category] = true
  }

  return usedCategories
})

export const selectTokens = createSelector([selectCardMap, selectCategories], (cardMap, categories) => {
  const tokens: Record<string, string[]> = {} // cardId: [card.name, card.name] // The cards that generate that token go in the Array
  const inDeckCategories = Object.keys(categories).reduce((acc, key) => {
    const category = categories[key]

    if (category.includedInDeck) acc.push(key)
    else if (['Tokens & Extras', 'Attraction', 'Attractions'].includes(category.name)) acc.push(key)

    return acc
  }, new Array<string>())

  for (const card of Object.values(cardMap)) {
    if (!card.tokens) continue

    const primaryCategory = card.categories[0]

    if (!inDeckCategories.includes(primaryCategory)) continue

    for (const tokenId of card.tokens) {
      if (!tokens[tokenId]) tokens[tokenId] = []

      tokens[tokenId].push(card.name)
    }
  }

  return tokens
})

export const selectOwnsDeck = createSelector(
  (state: RootState) => state.deck.id,
  (state: RootState) => state.deck.ownerid,
  (state: RootState) => state.deck.snapshotMeta,
  (deckId, ownerId, snapshotMeta) => {
    const userId = CookieService.get('tbId') // TODO - NEXT - will this work if we don't reload when logging in/out?

    if (!deckId) return true
    if (!!snapshotMeta) return false

    return `${ownerId}` === `${userId}`
  },
)

export const selectCanEditDeck = createSelector(selectOwnsDeck, (state: RootState) => state.deck.editors, checkCanEdit)

export const useCanEditDeck = () => {
  const DECK_EDITING_PAGES = ['/decks/[id]', '/decks/[...deckInfo]', '/sandbox']

  const checkCanEdit = (
    deckId: string | number,
    ownerId: string | number,
    snapshotMeta: any,
    editors: number[],
    userId: string,
    router: any,
  ): boolean => {
    if (!DECK_EDITING_PAGES.includes(router.pathname)) return false

    if (!deckId) return true
    else if (!!snapshotMeta) return false
    else {
      if (`${ownerId}` === `${userId}`) return true
      else if (editors.map(id => `${id}`).includes(userId)) return true
      else return false
    }
  }

  const router = useRouter()

  const deckId = useAppSelector(state => state.deck.id)
  const ownerId = useAppSelector(state => state.deck.ownerid)
  const snapshotMeta = useAppSelector(state => state.deck.snapshotMeta)
  const editors = useAppSelector(state => state.deck.editors)

  const [{ tbId: userId }] = useCookies(['tbId'])

  const [canEdit, setCanEdit] = useState(checkCanEdit(deckId, ownerId, snapshotMeta, editors, userId, router))

  useEffect(() => {
    const newCanEdit = checkCanEdit(deckId, ownerId, snapshotMeta, editors, userId, router)

    setCanEdit(newCanEdit)
  }, [deckId, ownerId, snapshotMeta, editors, userId, router])

  return canEdit
}

export const selectMaybeboard = createSelector(selectCardMap, selectCategories, (cardMap, categories) => {
  return Object.values(cardMap)
    .filter(card => !categories[card.categories[0]]?.includedInDeck)
    .map(card => card.id)
})

export const selectCommanders = createSelector(selectCardMap, selectCategories, (cardMap, categories) => {
  return Object.values(cardMap)
    .filter(card => categories[card.categories[0]]?.isPremier)
    .map(card => card.id)
})

export const selectSideboard = createSelector(selectCardMap, cardMap => {
  return Object.values(cardMap)
    .filter(card => card.categories[0] === 'Sideboard')
    .map(card => card.id)
})

export const selectCommanderCards = createSelector(selectCardMap, selectCategories, (cardMap, categories) => {
  return Object.values(cardMap).filter(card => categories[card.categories[0]]?.isPremier)
})

export const selectMaindeckCards = createSelector(selectCardMap, selectCategories, (cardMap, categories) => {
  return Object.values(cardMap).filter(
    card =>
      !categories[card.categories[0]]?.isPremier &&
      categories[card.categories[0]]?.includedInDeck &&
      card.categories[0] !== 'Sideboard',
  )
})

export const combinerSelectStacks = (
  cardMap: Record<string, CardType>,
  categories: Record<string, CategoryType>,
  sorting: SortOptionType,
  stack: StackOptionType,
  filteredCards: string[],
  priceSource: number[],
  isMobile: boolean,
  viewType: ViewType,
  selectShowEmptyCardStacks: boolean,
  selectHideDefaultTypeCategories: boolean,
  selectHideOutOfDeckCategories: boolean,
) => {
  const visableCards = filteredCards.length
    ? Object.values(cardMap).filter(card => filteredCards.includes(card.name))
    : Object.values(cardMap)

  const sortMethod = SortFunctionMap[`${sorting}`]
  const sortedCards = visableCards.sort((cardA, cardB) => sortMethod(cardA, cardB, priceSource))

  // Sort the cards into stacks
  const stackMap: Record<string, StackType> = {}
  for (const card of sortedCards) {
    const primaryCategory: CategoryType | undefined = categories[card.categories[0]]
    let stackNames = [primaryCategory?.name || '']

    if (!primaryCategory?.includedInDeck || primaryCategory?.name === 'Sideboard') stackNames = [primaryCategory?.name]
    else if (stack === STACK.MULTIPLE) stackNames = card.categories
    else if (primaryCategory?.isPremier) stackNames = [primaryCategory?.name]
    else if (stack === STACK.FULL_DECK) stackNames = ['Full Deck']
    else if (stack === STACK.CMC) stackNames = [getCardCMC(card).toString()]
    else if (stack === STACK.MANA_VALUE_SPELLS) stackNames = [getCmcForSpellsOnly(card).toString()]
    else if (stack === STACK.TYPES) stackNames = [card.typeCategory]
    else if (stack === STACK.COLOR_LABEL) stackNames = [card.colorLabel.name || 'Default Tag']
    else if (stack === STACK.COLLECTION) stackNames = [invert(HUMAN_OWNED)[card.owned]]
    else if (stack === STACK.RARITY) stackNames = [card.rarity.charAt(0).toUpperCase() + card.rarity.slice(1)]
    else if (stack === STACK.SALT) stackNames = [getSaltRange(card)]
    else if (stack === STACK.PRICE) stackNames = [getPriceRange(card, priceSource)]
    else if (stack === STACK.DATE_ADDED) stackNames = [getTimeFrames(card.createdAt)]
    else if (stack === STACK.CATEGORY_QUANTITY) stackNames = [`${card.categories.length}`]
    else if (stack === STACK.COLOR_IDENTITY) stackNames = [getColors(card.colorIdentity)]
    else if (stack === STACK.EDITION_NAME) stackNames = [card.set]
    else if (stack === STACK.ARTIST_NAME) stackNames = [card.artist]
    else if (stack === STACK.POWER) stackNames = [`${card.power ?? card.front?.power}` || 'N/A']
    else if (stack === STACK.TOUGHNESS) stackNames = [`${card.toughness ?? card.front?.toughness}` || 'N/A']

    // prettier-ignore
    else if (stack === STACK.COLOR) stackNames = [getColors([...card.colors, ...(card.front?.colors || []), ...(card.back?.colors || [])])]

    stackNames.forEach(stackName => {
      if (stackMap[stackName]) {
        stackMap[stackName].cardIds.push(card.id)
        stackMap[stackName].price += getPrice(card, priceSource[0]).priceNumber * card.qty
        stackMap[stackName].quantity += card.qty
      } else
        stackMap[stackName] = {
          name: stackName,
          cardIds: [card.id],
          price: getPrice(card, priceSource[0]).priceNumber * card.qty,
          quantity: card.qty,
          includedInDeck: !categories[stackName] || categories[stackName]?.includedInDeck,
          isPremier: categories[stackName] && categories[stackName].isPremier,
        }
    })
  }

  // Add empty stacks if the user wants to see them
  if (selectShowEmptyCardStacks && (stack === STACK.MULTIPLE || stack === STACK.CUSTOM)) {
    const categoryNames = Object.keys(categories)

    for (const categoryName of categoryNames) {
      if (stackMap[categoryName]) continue

      if (
        selectHideDefaultTypeCategories &&
        [
          'Artifact',
          'Conspiracy',
          'Creature',
          'Enchantment',
          'Instant',
          'Land',
          'Phenomenon',
          'Plane',
          'Planeswalker',
          'Scheme',
          'Sorcery',
          'Vanguard',
          'Tribal',
          'Battle',
          'Sideboard',
        ].includes(categoryName)
      )
        continue

      stackMap[categoryName] = {
        name: categoryName,
        cardIds: [],
        price: 0,
        quantity: 0,
        includedInDeck: categories[categoryName].includedInDeck,
        isPremier: categories[categoryName].isPremier,
      }
    }
  }

  // Used to sort strings with numerics naturally (eg: sorts word2 before word10, and ex3 before ex15)
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  // Sort the stacks themselves
  const allStacks = Object.values(stackMap).sort((a, b) => {
    if (NUMERIC_STACKS.includes(stack)) return orderByNumericStackHeader(a.name, b.name)
    if (stack === STACK.PRICE || stack === STACK.SALT) return collator.compare(a.name, b.name)
    if (TIME_STACKS.includes(stack)) return (TIME_RANGES[a.name] || 1000) > (TIME_RANGES[b.name] || 1000) ? 1 : -1

    if (stack === STACK.RARITY)
      return (RARITY_WEIGHTS[a.name as RarityOptionType] || 5) > (RARITY_WEIGHTS[b.name as RarityOptionType] || 5)
        ? 1
        : -1

    // prettier-ignore
    if (stack === STACK.COLOR || stack === STACK.COLOR_IDENTITY) return getIdentityNameOrdering(a.name) > getIdentityNameOrdering(b.name) ? 1 : -1

    return a.name > b.name ? 1 : -1
  })

  // Organize into sideDeck and mainDeck, placing an premier categories at the front of maindeck
  const regularStacks = allStacks.filter(
    stack => !stack.isPremier && stack.includedInDeck && stack.name !== 'Sideboard',
  )
  const commanders = allStacks.filter(stack => stack.isPremier && stack.includedInDeck && stack.name !== 'Sideboard')
  const sideboards = allStacks.filter(stack => stack.name === 'Sideboard')
  const maybeboards = allStacks.filter(stack => !stack.includedInDeck && stack.name !== 'Sideboard')

  const commanderOnRight =
    getBooleanFlagFromLocalStorage(rightCommanderKey) && !isMobile && viewType !== VIEW.GRID && viewType !== VIEW.SCROLL

  const mainDeck = commanderOnRight ? [...regularStacks] : [...commanders, ...regularStacks]
  const sideDeck = commanderOnRight ? [...commanders, ...sideboards] : [...sideboards]

  if (!selectHideOutOfDeckCategories) sideDeck.push(...maybeboards)

  return { mainDeck, sideDeck }
}

export const selectStacks = createSelector(
  [
    selectCardMap,
    selectCategories,
    selectSorting,
    selectStackType,
    selectFilteredCards,
    selectPriceSource,
    selectIsMobile,
    selectViewTypes,
    selectShowEmptyCardStacks,
    selectHideDefaultTypeCategories,
    selectHideOutOfDeckCategories,
  ],
  combinerSelectStacks,
)

/**
 * Given a card this selector finds the card preceding it and following it in the current sorting of the deck (null if
 * it is the first or last card). This is used by the BrowseArrow in the modal to navigate forward and backwards.
 */
export const selectPreviousAndNextCard = createSelector(
  selectStacks,
  selectCardMap,
  (_state: RootState, cardId: string) => cardId,
  ({ mainDeck, sideDeck }, cardMap, cardId) => {
    if (!cardId) return [undefined, undefined]

    const fullDeck = new Array<string>()

    mainDeck.forEach(stack => fullDeck.push(...stack.cardIds))
    sideDeck.forEach(stack => fullDeck.push(...stack.cardIds))

    for (let i = 0; i < fullDeck.length; i++) {
      const currentId = fullDeck[i]

      // Wraps around to tha back if we're at the front & wrap around to the front if we're at the back
      const previousCardId = fullDeck[i - 1] || fullDeck[fullDeck.length - 1]
      const nextCardId = fullDeck[i + 1] || fullDeck[0]

      if (currentId === cardId) {
        return [cardMap[previousCardId], cardMap[nextCardId]]
      }
    }

    return [undefined, undefined]
  },
)

export const selectMenuCard = createSelector(selectCardMap, selectMenuCardId, (cardMap, menuCardId) =>
  menuCardId !== undefined && menuCardId !== null ? cardMap[menuCardId] : undefined,
)
