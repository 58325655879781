import React, { useState } from 'react'
import Icon from 'components/elements/Icon'

import { CardType, OWNED } from 'types/deck'
import { CollectionV2Card } from 'redux/collectionV2/types'

import PhatButton from 'components/formElements/PhatButton'
import AddCardsModal from 'components/collectionV2/addCards/Modal'

import styles from './addToCollectionButton.module.scss'

type Props = {
  card: CardType
  onCardUpdated?: (updatedCard: CardType) => void
}

const AddToCollectionButton = ({ card, onCardUpdated }: Props) => {
  const [open, setOpen] = useState(false)

  const handleCardUpdated = (updatedCard: CollectionV2Card) => {
    if (!onCardUpdated || card.oracleCardId !== updatedCard.card.oracleCardId) return

    onCardUpdated({ ...card, owned: updatedCard.card.id === card.id ? OWNED.YES : OWNED.PRINTING })
  }

  const { owned } = card

  return (
    <>
      <PhatButton onClick={() => setOpen(true)}>
        {owned !== OWNED.NO ? (
          <span className={`${styles.ownedCollectionDot} ${owned === OWNED.YES ? styles.greenDot : ''}`} />
        ) : (
          <Icon name="address book" />
        )}
        Add to collection
      </PhatButton>

      <AddCardsModal
        includeGameTypeControls
        closeOnCreateOrUpdate
        open={open}
        cardToAdd={card}
        onCardCreatedOrUpdated={handleCardUpdated}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default AddToCollectionButton
