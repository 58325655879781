import React, { useState, useEffect } from 'react'
import { useAppSelector } from 'redux/hooks'

import { HUMAN_FORMAT } from 'types/deck'

import Select, { Options } from 'components/formElements/SemanticSelect'

const options: Options[] = Object.keys(HUMAN_FORMAT).map(key => ({ text: key, value: HUMAN_FORMAT[key] }))

type Props = {
  value?: number
  style?: React.CSSProperties
  onChange: (values: number) => void
}

const FormatDropdown = ({ value, style, onChange }: Props) => {
  const format = useAppSelector(state => state.deck.format)

  const [selectedValue, setSelectedValue] = useState(value ?? format ?? undefined)

  const formatedOptions = options.map(o => (o.text === 'Custom' ? { ...o, text: 'All Formats' } : o))

  const handleChange = (selectedValue: number) => {
    setSelectedValue(selectedValue)
    onChange(selectedValue)
  }

  return (
    <Select
      fluid
      search
      style={style}
      placeholder="Format"
      onChange={handleChange}
      options={formatedOptions}
      value={value ?? selectedValue}
    />
  )
}

export default FormatDropdown

type MutliProps = {
  value?: number[]
  onChange: (values: number[]) => void
}

// This is duplicated from above, but it's better than trying to add a bunch of conditionals for the types
// You're welcome to try to do it without a bunch of conditionals
export const MultiFormatDropdown = ({ value, onChange }: MutliProps) => {
  const [selectedOptions, setSelectedOptions] = useState(value ?? new Array<number>())

  const formatedOptions = options.map(o => (o.text === 'Custom' ? { ...o, text: 'All Formats' } : o))

  // Used to reset the form
  useEffect(() => {
    if (value !== undefined) return

    setSelectedOptions([])
    onChange([])
  }, [value])

  const handleChange = (selectedOptions: number[]) => {
    setSelectedOptions(selectedOptions)
    onChange(selectedOptions)
  }

  return (
    <Select placeholder="Formats" onChange={handleChange} options={formatedOptions} value={selectedOptions} multiple />
  )
}
