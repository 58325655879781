export const apiUrl = process.env.NEXT_PUBLIC_API_URL || ''
export const serverUrl = process.env.NEXT_PUBLIC_KUBERNETES_INTERNAL_URL || ''
export const wsUrl = process.env.NEXT_PUBLIC_WS_URL || ''
export const imageDirectory = process.env.NEXT_PUBLIC_IMAGE_DIRECTORY || ''
export const serviceWorkerFile = process.env.NEXT_PUBLIC_SERVICE_WORKER_FILE || ''
export const environmentName = process.env.NEXT_PUBLIC_ENV_NAME || 'local'
export const userVanityMapUrl = process.env.NEXT_PUBLIC_USER_VANITY_MAP_URL || ''
export const markdownPageDirectory = 'https://storage.googleapis.com/topdekt-user/text/'
// prettier-ignore
export const preconUserId = process.env.NEXT_PUBLIC_PRECON_USER_ID === 'null' ? null : process.env.NEXT_PUBLIC_PRECON_USER_ID || null
// prettier-ignore
export const defaultPackagesUserId = process.env.NEXT_PUBLIC_DEFAULT_PACKAGES_USER_ID === 'null' ? null : process.env.NEXT_PUBLIC_DEFAULT_PACKAGES_USER_ID || null
export const nodeEnv = process.env.NODE_ENV || 'development'
export const domain = process.env.NEXT_PUBLIC_DOMAIN_NAME || ''
export const staticHomePageURL = process.env.NEXT_PUBLIC_STAIC_HOME_PAGE_URL || '/api/localHomePage/'
export const staticCommunityPageURL = process.env.NEXT_PUBLIC_STAIC_COMMUNITY_PAGE_URL || '/api/localCommunityPage/'
export const googleAnalyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ''
export const nitroId = process.env.NEXT_PUBLIC_NITO_ID || ''
export const mediavineUid = process.env.NEXT_PUBLIC_MEDIAVINE_UID || ''
export const communityRecordId = process.env.NEXT_PUBLIC_COMMUNITY_RECORD_ID || ''
export const isDowntime = process.env.NEXT_PUBLIC_DOWNTIME_ACTIVE === 'true' || false
export const enableAdsOnLocalhost = process.env.NEXT_PUBLIC_ENABLE_ADS_ON_LOCALHOST === 'true' || false
export const reCaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''

// This gets set by GCP durring the build process, this build flag won't be set locally and only shows up (correctly) when the client is build through GCP
// Docs below for other availible vars
// In order to access the BUILD_ID var it needed to also be added to the cloudbuild.yaml for the build step, so if you want to add more, you'll need to change that oo
// https://cloud.google.com/build/docs/configuring-builds/substitute-variable-values?_ga=2.75815120.-41593458.1662388193
export const buildId = process.env.NEXT_PUBLIC_BUILD_ID || 'N/A'

const hasWindow = typeof window !== 'undefined'

export const getDomain = () =>
  hasWindow && window.location.origin.includes('archidekt')
    ? 'Archidekt'
    : hasWindow && window.location.origin.includes('topdekt')
    ? 'Topdekt'
    : 'Local'

const getUa = () => (hasWindow ? window.navigator.userAgent : '')
const getIOS = () => !!getUa()?.match(/iPad/i) || !!getUa()?.match(/iPhone/i) || false
const getWebkit = () => !!getUa()?.match(/WebKit/i) || false
const getIOSSafari = () => (getIOS() && getWebkit() && !getUa()?.match(/CriOS/i)) || false
const getIsChrome = () => getUa()?.indexOf('Chrome') > -1 || false
const getIsSafari = () => (!getIsChrome() && getUa()?.indexOf('Safari') > -1) || false
const getIsFirefox = () => hasWindow && navigator.userAgent.indexOf('Firefox') !== -1 // not perfect, but good enough
const getIsEdge = () => hasWindow && navigator.userAgent.indexOf('Edg') > -1
const getIsPWA = () => hasWindow && window.matchMedia('(display-mode: standalone)').matches //  returns true if the user is running as a standalone app
const getOnMac = () => hasWindow && /mac/i.test(navigator?.platform)
const tcgPropertyId = '5237568' // from tcg player partner site
const scgAffiliateId = '6'
const manapoolAffiliateUrlParams = 'ref=scm&tap_s=5258590-8677e0'
const tcgLandAffiliateUrlParam = 'edhrec'

const runningOnPrivateServer = nodeEnv !== 'development' && domain === 'topdekt'
export const modOnly = process.env.NEXT_PUBLIC_MOD_ONLY === 'true' || false // Only will ever take effect on private servers, useful for disabling topdekt for anyone without the mod role

export default {
  apiUrl,
  serverUrl,
  nodeEnv,
  domain,
  staticHomePageURL,
  staticCommunityPageURL,
  imageDirectory,
  serviceWorkerFile,
  environmentName,
  userVanityMapUrl,
  preconUserId,
  defaultPackagesUserId,
  buildId,
  getIOSSafari,
  getIsFirefox,
  getIsEdge,
  getIsSafari,
  getIsChrome,
  getIsPWA,
  getOnMac,
  markdownPageDirectory,
  googleAnalyticsId,
  nitroId,
  mediavineUid,
  runningOnPrivateServer,
  communityRecordId,
  modOnly,
  isDowntime,
  enableAdsOnLocalhost,
  reCaptchaSiteKey,
  tcgPropertyId,
  scgAffiliateId,
  manapoolAffiliateUrlParams,
  tcgLandAffiliateUrlParam,
}
