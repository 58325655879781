import { useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import Icon from 'components/elements/Icon'
import { cloneDeep } from 'lodash'

import LocalStorageService from 'services/localStorage.service'

import { useAppSelector } from 'redux/hooks'
import { selectStacks } from 'redux/deck/selectors'
import { selectTextColumnWidth } from 'redux/cacheables/selectors'

import ImageStack from 'components/deckPage/DeckContainerV2/cardGroups/ImageStack'
import TextStack from 'components/deckPage/DeckContainerV2/cardGroups/TextStack'
import StickiedDeckControls from 'components/deckPage/filtersAndControls/StickiedDeckControls'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import PhatButton from 'components/formElements/PhatButton'

import { StackType, VIEW } from 'types/deck'

import { STACK_WIDTH } from 'components/deckPage/DeckContainerV2/layouts/helperFunctions'

import styles from './manualStack.module.scss'

type Props = {}

const ManualStackLayout = ({}: Props) => {
  const deckId = useAppSelector(state => state.deck.id)
  const snapshotMeta = useAppSelector(state => state.deck.snapshotMeta)

  const view = useAppSelector(state => state.deck.view)
  const textColumnWidth = useAppSelector(selectTextColumnWidth)
  const imageSize = useAppSelector(state => state.cacheables.imageSize)
  const disableMargins = useAppSelector(state => state.cacheables.disableMargins)
  const showEmptyCardStacks = useAppSelector(state => state.cacheables.showEmptyCardStacks)
  const hideDefaultTypeCategories = useAppSelector(state => state.cacheables.hideDefaultTypeCategories)
  const categories = useAppSelector(state => state.deck.categories)

  const containerRef = useRef<HTMLDivElement>(null)

  const { mainDeck, sideDeck } = useAppSelector(selectStacks)

  const [mounted, setMounted] = useState(false)
  const [simplifiedStack, setSimplifiedStack] = useState(false)
  const [columns, setColumns] = useState<string[][]>([])

  const gridStyles: React.CSSProperties = { gridTemplateColumns: `repeat(${columns.length}, 1fr)` }
  const stackWidth = view === VIEW.TEXT ? textColumnWidth : STACK_WIDTH[imageSize]
  const stackMap: Record<string, StackType> = [...mainDeck, ...sideDeck].reduce(
    (acc, stack) => ({ ...acc, [stack.name]: stack }),
    {},
  )

  useEffect(() => {
    const cachedDeckLayout = LocalStorageService.getObject(generateLocalStorageKey(deckId, !!snapshotMeta))

    if (cachedDeckLayout) setColumns(cachedDeckLayout)
    else handleInitialLayoutSetup()

    setMounted(true)
  }, [])

  useEffect(() => {
    if (!mounted) return

    handleConfirmDisplayColumns()

    LocalStorageService.setObject(generateLocalStorageKey(deckId, !!snapshotMeta), columns)
  }, [mainDeck, sideDeck, columns, showEmptyCardStacks])

  const handleInitialLayoutSetup = () => {
    const containerWidth = containerRef.current?.offsetWidth || window.innerWidth
    const width = containerWidth > 2300 && !disableMargins ? 2300 : containerWidth
    const initialColumns = Math.floor(width / stackWidth) || 1

    const initalDisplayColumns: string[][] = Array.from(Array(initialColumns).keys()).map(() => [])

    for (let i = 0; i < mainDeck.length; i++) {
      const stack = mainDeck[i]
      const allowedColumns = sideDeck.length && initialColumns > 1 ? initialColumns - 1 : initialColumns

      const columnPlacement = i % allowedColumns

      initalDisplayColumns[columnPlacement].push(stack.name)
    }

    if (sideDeck.length) {
      const sideDeckNames = sideDeck.map(stack => stack.name)

      if (initialColumns > 1) initalDisplayColumns[initialColumns - 1] = sideDeckNames
      else initalDisplayColumns[0].push(...sideDeckNames)
    }

    setColumns(initalDisplayColumns)
  }

  const handleConfirmDisplayColumns = () => {
    const stacks = [...mainDeck, ...sideDeck]
    const stackNames = stacks.map(stack => stack.name)
    const stacksOnDisplay: Record<string, true> = columns
      .flat()
      .reduce((acc, stackName) => ({ ...acc, [stackName]: true }), {})

    const notShownStacks = stackNames.filter(stackName => !stacksOnDisplay[stackName])

    if (notShownStacks.length === 0) return // There are no stacks that aren't currently placed, move on
    if (columns.length === 0) return // Not sure what could have happened here, but just bale, let them figure it out

    const updatedColumns = cloneDeep(columns)
    const nextEmptColumn = columns.findIndex(column => column.length === 0)

    // If there's no open column, add to the second to last column
    // If the only open column is the last column, still put it in last column
    let colToUpdate = nextEmptColumn === -1 ? columns.length - 1 : nextEmptColumn

    // IF they have a side / maybeboard, and have more than 1 column (aka, the sidedeck is in it's own column), add to the second to last column
    // If there's only 1 column, just add it to the end of the single column
    if (!!sideDeck.length && columns.length > 1 && colToUpdate === columns.length - 1) colToUpdate = columns.length - 2

    updatedColumns[colToUpdate] = [...columns[colToUpdate], ...notShownStacks]

    setColumns(updatedColumns)
  }

  const handleRemoveColumn = (removedIndex: number) => {
    const columnToRemove = columns[removedIndex]

    if (!columnToRemove || columns.length === 1) return // Protection against
    if (columnToRemove.length === 0) return setColumns(columns.filter((_c, i) => i !== removedIndex))

    const updatedColumns = cloneDeep(columns)

    if (removedIndex === 0) updatedColumns[1] = [...columnToRemove, ...updatedColumns[1]]
    else updatedColumns[removedIndex - 1] = [...updatedColumns[removedIndex - 1], ...columnToRemove]

    setColumns(updatedColumns.filter((_c, i) => i !== removedIndex))
  }

  const handleSetSingleColumnPerStack = () => {
    const updatedColumns: string[][] = []

    for (const stack of [...mainDeck, ...sideDeck]) updatedColumns.push([stack.name])

    setColumns(updatedColumns)
  }

  const handleDragEnd = (result: any) => {
    setSimplifiedStack(false)

    const { source, destination } = result

    // dropped outside the list
    if (!destination) return

    const { index: placeInColumn, droppableId: columnId } = source
    const { index: newPlaceInColumn, droppableId: newColumnId } = destination

    const columnIndex = parseInt(columnId)
    const newColumnIndex = parseInt(newColumnId)

    const stackName = columns[columnIndex][placeInColumn]

    const updatedOldColumn = columns[columnIndex].filter((_, index) => index !== placeInColumn)
    const existingDroppedColumn = columnIndex === newColumnIndex ? updatedOldColumn : columns[newColumnIndex] // Note - we're grabbing the updated old column if we're moving within the same column since the index will have changed

    // prettier-ignore
    const updatedNewColumn = [...existingDroppedColumn.slice(0, newPlaceInColumn), stackName, ...existingDroppedColumn.slice(newPlaceInColumn)]

    const updatedDisplayColumns = [...columns]

    updatedDisplayColumns[columnIndex] = updatedOldColumn
    updatedDisplayColumns[newColumnIndex] = updatedNewColumn

    setColumns(updatedDisplayColumns)
  }

  // This makes it so EMPTY stacks that have been placed in a column are not treated as shown
  // The only thing this does is make it so that a column appears empty (aka the remove column button appears) when those stacks are hidden
  // Actually removing the empty stacks from the columns would be inconveinient since re-enabling the feature would place them at the end of the list rather than where they were originally placed
  // It also alows categories to be removed from the deck without removing them from the layout (aka, if they're re-added they'll still be in the same place)
  const shownColumns = columns.map(columns => {
    return columns.filter(stackName => {
      const categoryStillExists = !!categories[stackName]
      const categoryHasCards = !!stackMap[stackName]?.cardIds?.length
      const categoryIsDefaultTypeCategory = [
        'Artifact',
        'Conspiracy',
        'Creature',
        'Enchantment',
        'Instant',
        'Land',
        'Phenomenon',
        'Plane',
        'Planeswalker',
        'Scheme',
        'Sorcery',
        'Vanguard',
        'Tribal',
        'Battle',
        'Sideboard',
      ].includes(stackName)

      if (showEmptyCardStacks && hideDefaultTypeCategories)
        return categoryStillExists && (categoryHasCards || !categoryIsDefaultTypeCategory)
      if (showEmptyCardStacks && !hideDefaultTypeCategories) return categoryStillExists

      return categoryStillExists && categoryHasCards
    })
  })

  return (
    <>
      <div className={`${styles.controls} ${disableMargins ? styles.disableMargins : ''}`}>
        <PhatButton color="green" className={styles.addColumnButton} onClick={() => setColumns([...columns, []])}>
          <Icon name="add circle" /> Add column
        </PhatButton>
        <ArchidektDropdown
          options={[
            {
              label: 'Set single stack per column',
              onClick: handleSetSingleColumnPerStack,
            },
            {
              label: 'Reset layout to screen default',
              onClick: () => handleInitialLayoutSetup(),
            },
            {
              type: 'extras',
              label: 'Remove display column',
              id: 'remove-column-by-index',
              options: [
                ...columns.map((_column, index) => ({
                  label: `Column ${index + 1} (${stackMap[columns[index][0]]?.name || 'Empty'})`,
                  className: styles.removeColumnOption,
                  onClick: () => handleRemoveColumn(index),
                })),
              ],
            },
          ]}
          menuClassName={styles.extraOptionsMenu}>
          <PhatButton className={styles.extraOptionsTrigger}>
            <Icon name="cog" />
          </PhatButton>
        </ArchidektDropdown>
      </div>

      <div className={styles.container} ref={containerRef}>
        {mounted && (
          <>
            <StickiedDeckControls />

            <DragDropContext onDragEnd={handleDragEnd}>
              <div className={styles.grid} style={gridStyles}>
                {shownColumns.map((column, columnNumber) => (
                  <Droppable key={`column-${columnNumber}`} droppableId={`${columnNumber}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${styles.column} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
                        style={{ width: stackWidth }}>
                        {column.map((stackId, placeInColumn) => (
                          <Draggable
                            key={placeInColumn}
                            draggableId={`column-${columnNumber}-index-${placeInColumn}`}
                            index={placeInColumn}>
                            {(provided, _snapshot) => {
                              const stack = stackMap[stackId]

                              // Needed to prevent beautiful DnD from freaking the fuck out
                              if (!stack)
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  />
                                )

                              return (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  style={{ ...provided.draggableProps.style }}>
                                  {view === VIEW.TEXT ? (
                                    <TextStack
                                      key={`placement-${placeInColumn}-text`}
                                      dragHandleProps={provided.dragHandleProps}
                                      onDragTriggerMouseDown={() => setSimplifiedStack(true)}
                                      onDragTriggerMouseUp={() => setSimplifiedStack(false)}
                                      cardIds={stack.cardIds}
                                      name={stack.name}
                                      price={stack.price}
                                      quantity={stack.quantity}
                                    />
                                  ) : (
                                    <ImageStack
                                      simplifiedDOMStack={simplifiedStack}
                                      dragHandleProps={provided.dragHandleProps}
                                      onDragTriggerMouseDown={() => setSimplifiedStack(true)}
                                      onDragTriggerMouseUp={() => setSimplifiedStack(false)}
                                      key={`placement-${placeInColumn}-image`}
                                      cardIds={stack.cardIds}
                                      name={stack.name}
                                      price={stack.price}
                                      quantity={stack.quantity}
                                      isPremier={stack.isPremier}
                                      includedInDeck={stack.includedInDeck}
                                    />
                                  )}
                                </div>
                              )
                            }}
                          </Draggable>
                        ))}

                        {!column.length && (
                          <div className={styles.emptyStack}>
                            <PhatButton color="red" onClick={() => handleRemoveColumn(columnNumber)}>
                              Remove column
                            </PhatButton>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </div>
            </DragDropContext>
          </>
        )}
      </div>
    </>
  )
}

export default ManualStackLayout

export const generateLocalStorageKey = (deckId: number | string, isSnapshot?: boolean) => {
  if (isSnapshot) return `tb-${deckId}-manual-layout-snapshot`

  return `tb-${deckId}-manual-layout`
}
