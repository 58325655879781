import queryString from 'query-string'
import { isEqual, invert } from 'lodash'
import { NextRouter } from 'next/router'

import { defaultPackagesUserId } from 'environment'

import { ColorSearchOptions, RaritySearchOptions, SearchOptions } from 'services/apiTypes/card.types'

import { CardSearchType, DeckSearchType, PackageTabs } from 'types/search'
import { ArchidektSearchMeta, generateArchidektSearchState } from 'types/searchV2'
import { CollectionFiltersType, DEFAULT_COLLECTION_FILTERS } from 'types/collection'
import { PRICE_SOURCE } from 'types/active'
import { FormatType, HUMAN_READABLE_FORMAT } from 'types/deck'

import { searchParamsToArgs } from './SearchParams'
import { EstimatedBudget } from 'services/apiTypes/deck.types'

export const colors: (searchState: Partial<DeckSearchType>) => Array<string> = searchState => {
  const result = []
  if (searchState.w === undefined || searchState.w) result.push('White')
  if (searchState.u === undefined || searchState.u) result.push('Blue')
  if (searchState.b === undefined || searchState.b) result.push('Black')
  if (searchState.r === undefined || searchState.r) result.push('Red')
  if (searchState.g === undefined || searchState.g) result.push('Green')
  if (searchState.colorless === undefined || searchState.colorless) result.push('Colorless')
  return result
}

export const rarity: (cardSearch: CardSearchType) => Array<string> = cardSearch => {
  const result = []
  if (cardSearch.common) result.push('common')
  if (cardSearch.uncommon) result.push('uncommon')
  if (cardSearch.rare) result.push('rare')
  if (cardSearch.mythic) result.push('mythic')
  return result
}

export const stringListToCardString: (input: Array<string> | string) => string = input => {
  if (typeof input === 'string') return input

  return input.map(str => (str.includes('"') ? `"${str.replace(/"/g, '""')}"` : `"${str}"`)).join(',')
}

export const deckSearchStateToSearchParams = (state: Partial<DeckSearchType>): string => {
  const args: Record<string, any> = {}
  if (state.name) args.name = state.name
  if (state.followed) args.followed = true
  if (state.recentId) args.recentId = state.recentId
  if (state.ids && state.ids.length) args.ids = state.ids.join(',')
  if (state.username) args.owner = state.username
  if (state.userid) args.userid = state.userid
  if (state.exactUsername) args.ownerexact = true
  if (state.size) args.size = state.size
  if (state.sizelogic === '>=') args.gtesize = true
  if (state.sizelogic === '<=') args.ltesize = true
  if (state.formats && state.formats.length && Array.isArray(state.formats)) args.formats = state.formats.join(',')
  if (state.formats && !Array.isArray(state.formats)) args.formats = state.formats
  if (state.cards && state.cards.length) args.cards = stringListToCardString(state.cards)
  if (state.commanders && state.commanders.length) args.commanders = stringListToCardString(state.commanders)
  if (state.deckTags && state.deckTags.length) args.deckTags = stringListToCardString(state.deckTags)
  if (colors(state).length < 6) args.colors = colors(state).join(',')
  if (!state.colorOr && args.colors) args.andcolors = true
  if (state.orderBy) args.orderBy = (state.ascending ? '' : '-') + state.orderBy
  if (state.sharedWithMe) args.sharedWithMe = true
  if (state.myBookmarks) args.myBookmarks = true
  if (state.packages) args.packages = true
  if (state.intendedFormats) args.intendedFormats = state.intendedFormats.join(',')
  if (state.budget) args.budget = state.budget
  if (state.packages) args.packages = true

  return queryString.stringify(args)
}

const ARRAY_FIELDS = ['editions', 'superTypes', 'types', 'subTypes']
const BOOLEAN_FIELDS = [
  'exact',
  'includeTokens',
  'includeDigital',
  'allEditions',
  'random',
  'andcolors',
  'colorIdentity',
  'descending',
  'gtecmc',
  'ltecmc',
  'gtepower',
  'ltepower',
  'gtetoughness',
  'ltetoughnesss',
]

/**
 * This function takes in a ArchidektSearchMeta state and converts it to a queryString.
 */
export const cardSearchStateToSearchParams = (state: ArchidektSearchMeta): string => {
  const reference = generateArchidektSearchState()

  const query: Record<string, any> = {}

  const alteredFields = Object.keys(reference).filter(key => {
    const defaultState = reference[key as keyof SearchOptions]
    const currentState = state[key as keyof SearchOptions]

    return !isEqual(defaultState, currentState)
  }) as Array<keyof ArchidektSearchMeta>

  for (const key of alteredFields) {
    if (ARRAY_FIELDS.includes(key)) query[key] = (state[key] as string[]).join(',')
    else if (key === 'colors' && state.colors)
      query.colors = Object.keys(state.colors)
        .filter(color => state.colors && state.colors[color as keyof ColorSearchOptions])
        .join(',')
    else if (key === 'rarity' && state.rarity)
      query.rarity = Object.keys(state.rarity)
        .filter(rarity => state.rarity && state.rarity[rarity as keyof RaritySearchOptions])
        .join(',')
    else query[key] = state[key]
  }

  return queryString.stringify(query)
}

export const queryStringToSearchState = (str: string): ArchidektSearchMeta => {
  const base: Record<string, any> = generateArchidektSearchState()
  const query: Record<string, any> = queryString.parseUrl(str).query

  for (const key in query) {
    if (ARRAY_FIELDS.includes(key)) base[key] = query[key].split(',')
    else if (BOOLEAN_FIELDS.includes(key)) base[key] = query[key]
    else if (key === 'colors')
      Object.keys(base.colors).forEach(color => {
        if (!query.colors.split(',').includes(color)) base.colors[color] = false
      })
    else if (key === 'rarity')
      Object.keys(base.rarity).forEach(rarity => {
        if (!query.rarity.split(',').includes(rarity)) base.rarity[rarity] = false
      })
    else base[key] = query[key]
  }

  return base
}

export const collectionSearchStateToSearchParams = (state: CollectionFiltersType): string => {
  const query: Record<string, any> = { ...state }

  for (const key in query) {
    const value = query[key]
    if (value === '') query[key] = undefined
    if (Array.isArray(value)) query[key] = value.length ? value.join(',') : undefined
  }

  return queryString.stringify(query)
}

const COLLECTION_ARRAY_FIELDS: Array<keyof CollectionFiltersType> = ['condition', 'collectionLanguage', 'tags']
const COLLECTION_STANDARD_FIELDS: Array<keyof CollectionFiltersType> = ['quantity', 'quantityLogic', 'treatment']
export const collectionSearchParamsToSeachState = (query: string): CollectionFiltersType => {
  const queryObject = queryString.parse(query)
  const base: CollectionFiltersType = { ...DEFAULT_COLLECTION_FILTERS }

  COLLECTION_ARRAY_FIELDS.forEach(key => {
    if (queryObject[key] && key === 'tags') base[key] = `${queryObject[key]}`.split(',') as any
    else if (queryObject[key]) base[key] = `${queryObject[key]}`.split(',').map(str => Number(str)) as any
  })

  COLLECTION_STANDARD_FIELDS.forEach(key => {
    if (queryObject[key] !== undefined) base[key] = `${queryObject[key]}` as any
  })

  return base
}

export const getCollectionOrderFieldName = (fieldName: string, priceSource: number) =>
  fieldName === 'price' ? `card__prices__${invert(PRICE_SOURCE)[priceSource].toLowerCase()}` : fieldName

/**
 * The point of this function is to generate a great title and description for the deck search page. Check
 *
 * @param router
 * @returns SEO optimized title and description for the deck search page
 */
export const getSEOOptimizedInfo = (router: NextRouter): { description: string; title: string } => {
  let title = ''
  let description = ''

  const getTitleSeparator = () => (title.length ? ' - ' : '')
  const getDescSeparator = () => (description.length ? ' - ' : '')

  try {
    const params = searchParamsToArgs(router)

    if (params.commanders.length) {
      title += `${params.commanders.join(' and ')} decks`
      description += `${params.commanders.join(' and ')} decks on`
    }

    if (params.formats.length) {
      // @ts-ignore
      const humanFormats = params.formats.map(id => HUMAN_READABLE_FORMAT[id] || 'Custom')

      title += `${getTitleSeparator()}${humanFormats.join(' and ')} decks`
      description += `${getDescSeparator()}${humanFormats.join(' and ')} Magic: The Gathering decks`
    }

    if (params.name) {
      title += `${getTitleSeparator()}${params.name}`
      description += `${getDescSeparator()}${params.name}`
    }

    if (params.deckTags.length && Array.isArray(params.deckTags)) {
      title += `${getTitleSeparator()}${params.deckTags.map(t => decodeURIComponent(t)).join(', ')} decks`
      description += `${getTitleSeparator()}${params.deckTags
        .map(t => decodeURIComponent(t))
        .join(', ')} Magic: The Gathering decks`
    }

    if (params.exactUsername && params.username) {
      // prettier-ignore
      description = `${params.username}'s ${!params.commanders.length && !params.formats.length ? 'decks' : ''}${description}`
      title = `${params.username}'s ${!params.commanders.length && !params.formats.length ? 'decks' : ''}${title}`
    }

    if (!params.packages) {
      title += `${getTitleSeparator()}Archidekt search`
      description += `${getDescSeparator()}Archidekt deck search. Filter and sort decks by format, commander, name, and more.`
    } else {
      title += `${getTitleSeparator()}Archidekt card packages`
      description += `${getDescSeparator()}Archidekt card packages. View user generated, pre-built card packages for Magic: The Gathering.`
    }
  } catch (e) {
    // Fallthrough to finally. Malformed query string provided
    console.error(e)
  } finally {
    return { description, title }
  }
}

export const getQueryParamsBySettings = (
  searchType: PackageTabs,
  name: string,
  username?: string,
  orderBy?: string,
  intendedFormats?: FormatType[],
  budget?: EstimatedBudget,
): Partial<DeckSearchType> => {
  const defaults: Partial<DeckSearchType> = {
    name,
    orderBy,
    intendedFormats,
    budget,
    packages: true,
    ascending: orderBy === 'name' ? true : false,
  }

  if (searchType === 'default' && defaultPackagesUserId) return { ...defaults, userid: defaultPackagesUserId }
  if (searchType === 'mine') return { ...defaults, exactUsername: true, username }
  if (searchType === 'followed') return { ...defaults, myBookmarks: true }

  return defaults
}
