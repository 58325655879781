import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Head from 'next/head'
import * as Sentry from '@sentry/browser'

import { DEFAULT_DESCRIPTION, SMALL_DESCRIPTION } from 'utils/DefaultDescription'

type Props = {
  title?: string | null
  description?: string
  robots?: string
}

/**
 * This component uses the React Helmet library to set the metadata for a page. It takes in the title, description and
 * robots props to set those meta data fields if values are provided.
 */
const Headers = ({ title, description, robots }: Props) => {
  const [{ tbUser: username }] = useCookies(['tbUser'])

  useEffect(() => {
    if (username) Sentry.setUser({ username })
  }, [])

  return (
    <Head>
      {title && <title>{title || 'Archidekt'}</title>}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {robots && <meta name="robots" content={robots} />}
      <meta name="description" content={description ? `${description}\n${SMALL_DESCRIPTION}` : DEFAULT_DESCRIPTION} />
    </Head>
  )
}

export default Headers
