import { string } from 'prop-types'
import { CardPackageInfo, EstimatedBudget } from 'services/apiTypes/deck.types'
import { DeckTagRelation } from 'services/apiTypes/deckTag.types'
import { FormatType } from './deck'
import { FORMAT, HUMAN_GAME } from './deck'

export const IN_DECK = {
  NOT_IN_DECK: 0,
  NAME_IN_DECK: 1,
  EXACT_IN_DECK: 2,
}

export const defaultCardSearch: CardSearchType = {
  searchValue: '',
  w: true,
  u: true,
  b: true,
  r: true,
  g: true,
  colorless: true,
  colorOr: true,
  colorIdentity: false,
  common: true,
  uncommon: true,
  rare: true,
  mythic: true,
  sets: [],
  supertypes: [],
  types: [],
  subtypes: [],
  typesOr: true,
  text: '',
  textOr: true,
  cmclogic: '=',
  cmc: '',
  manacost: '',
  powerlogic: '=',
  power: '',
  toughnesslogic: '=',
  toughness: '',
  flavor: '',
  artist: '',
  lore: '',
  orderBy: 'name',
  layout: 'normal',
  activeIndex: -1,
  ascending: true,
  unique: true,
  formatLegality: FORMAT.CUSTOM,
  game: HUMAN_GAME.Paper,
  collection: 0,
  collectorNumber: '',
  page: 1,
}
export type CardSearchType = {
  searchValue: string
  w: boolean
  u: boolean
  b: boolean
  r: boolean
  g: boolean
  colorless: boolean
  colorOr: boolean
  colorIdentity: boolean
  common: boolean
  uncommon: boolean
  rare: boolean
  mythic: boolean
  sets: Array<string>
  supertypes: Array<string>
  types: Array<string>
  subtypes: Array<string>
  typesOr: boolean
  text: string
  textOr: boolean
  cmclogic: string
  cmc: string
  manacost: string
  powerlogic: string
  power: string
  toughnesslogic: string
  toughness: string
  flavor: string
  artist: string
  lore: string
  orderBy: string
  layout: string
  activeIndex: number
  ascending: boolean
  unique: boolean
  formatLegality: FormatType
  game: number
  collection: number
  collectorNumber: string
  page: number
}

export const generateDefaultDeckSearch = (queryParams?: Record<string, any>): DeckSearchType => ({
  name: '',
  w: true,
  u: true,
  b: true,
  r: true,
  g: true,
  colorless: true,
  colorOr: true,
  size: '',
  sizelogic: '=',
  formats: [],
  cards: [],
  commanders: [],
  username: '',
  userid: 0,
  exactUsername: false,
  orderBy: 'updatedAt',
  layout: 'normal',
  activeIndex: -1,
  ascending: false,
  page: 1,
  ids: [],
  recentId: 0,
  followed: false,
  sharedWithMe: false,
  myBookmarks: false,
  deckTags: [],
})

export type DeckSearchType = {
  name: string
  w: boolean
  u: boolean
  b: boolean
  r: boolean
  g: boolean
  colorless: boolean
  colorOr: boolean
  size: string
  sizelogic: string
  formats: Array<string> | number[]
  cards: Array<string>
  commanders: Array<string>
  username: string
  userid: number | string
  exactUsername: boolean
  orderBy: string
  layout: string
  activeIndex: number
  ascending: boolean
  page: number
  ids: string[] | number[]
  recentId: number | string
  followed: boolean
  sharedWithMe: boolean
  myBookmarks: boolean
  pageSize?: number
  deckTags: string[]
  packages?: boolean
  intendedFormats?: FormatType[]
  budget?: EstimatedBudget
}

export const equalsOptions = [
  { text: '=', value: '=' },
  { text: '>=', value: '>=' },
  { text: '<=', value: '<=' },
]

export const PAGE_SIZE = {
  CARD_SEARCH: 40,
  SCRYFALL_SEARCH: 35,
  USER_SEARCH: 24,
  DECK_SEARCH: 48,
  NEWS: 18,
}

export type DeckCardInfoType = {
  owner: {
    id: number
    username: string
    profile: number
    avatar: string
    pledgeLevel: number | null
    roles: number[]
  }
  colors: DeckListColors
  customFeatured: string
  deckFormat: FormatType
  featured: string
  id: number
  name: string
  private: boolean
  unlisted: boolean
  updatedAt: string
  viewCount: number
  bookmarked: boolean | null
  tags: DeckTagRelation[]
  cardPackage: null | CardPackageInfo
}

export type SearchEnumTypes = 'card' | 'deck'
export type SearchPropTypes = 'cardSearch' | 'deckSearch'
export type PackageTabs = 'default' | 'mine' | 'followed' | 'search'
export type DeckListColors = { W?: number; U?: number; B?: number; R?: number; G?: number }
