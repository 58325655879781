import { NextRouter } from 'next/router'
import queryString from 'query-string'

import { DeckSearchType } from 'types/search'
import { generateDefaultDeckSearch } from 'types/search'

import { urlToTag } from 'utils/deckTagAliases'

export const searchParams = (input: Record<string, any>, mark = true): string => {
  let output = ''
  let count = 0
  for (const key in input) {
    output += (count === 0 ? (mark ? '?' : '') : '&') + key + '=' + encodeURIComponent(input[key])
    count += 1
  }
  return output
}

function parseCSVLine(csvLine: string) {
  let results = []
  let match
  let regex = /(?:,|^)(?:"([^"]*(?:""[^"]*)*)"|([^",]*))/g

  while ((match = regex.exec(csvLine))) {
    let value = match[1] !== undefined ? match[1].replace(/""/g, '"') : match[2]
    results.push(value)
  }

  return results
}

export const paramStringToObject = (input: string): Record<string, any> => {
  if (input[0] === '&' || input[0] === '?') {
    input = input.substring(1, input.length)
  }
  let output: Record<string, any> = {}
  input.split('&').forEach(potentiallyEncodedString => {
    const param = decodeURIComponent(potentiallyEncodedString)

    const splitParam = param.split('=')
    if (splitParam.length === 2) {
      const param_name = splitParam[0]
      let param_value: string | string[] = splitParam[1]
      if (param_value.includes(',') || param_value.includes('"')) {
        if (param_value.includes('"')) {
          param_value = parseCSVLine(param_value).map(str => str.trim())
        } else {
          param_value = param_value.split(',')
        }
      }
      output[param_name] = param_value
    } else {
      output[param] = true
    }
  })
  return output
}

export const searchParamsToArgs = (router: NextRouter): DeckSearchType => {
  const { query } = router
  const searchString = queryString.stringify(query)

  let params = paramStringToObject(searchString)
  let args: DeckSearchType = { ...generateDefaultDeckSearch() }
  if (params.name) args.name = params.name
  if (params.owner) args.username = params.owner
  if (params.ownerexact) args.exactUsername = true
  if (params.orderBy) {
    if (params.orderBy[0] === '-') {
      args.ascending = false
      args.orderBy = params.orderBy.substr(1)
    } else {
      args.ascending = true
      args.orderBy = params.orderBy
    }
  }
  if (params.sharedWithMe) args.sharedWithMe = params.sharedWithMe
  if (params.myBookmarks) args.myBookmarks = params.myBookmarks
  if (params.size) args.size = params.size
  if (params.ltesize) args.sizelogic = '<='
  else if (params.gtesize) args.sizelogic = '>='
  else args.sizelogic = '='

  if (params.formats) {
    let formats = params.formats
    if (!Array.isArray(formats)) {
      formats = [formats]
    }
    args.formats = formats.map((str: string) => parseInt(str, 10))
  }

  const aliasUrlDeckTags = query.tagName ? [urlToTag(`${query.tagName || ''}`)] : ''

  if (params.cards) args.cards = params.cards
  if (params.commanders) args.commanders = params.commanders
  if (params.deckTags || aliasUrlDeckTags) args.deckTags = params.deckTags || aliasUrlDeckTags
  if (params.packages || router.pathname.includes('card-packages')) args.packages = true
  if (router.pathname === '/commander-precons') {
    args.exactUsername = true
    args.username = 'Archidekt_Precons'
  }

  if (params.andcolors === 'true') args.colorOr = false
  if (params.colors) {
    let colors = {
      w: false,
      u: false,
      b: false,
      r: false,
      g: false,
      colorless: false,
    }
    let array = params.colors
    if (!Array.isArray(array)) {
      array = [array]
    }
    array.forEach((color: string) => {
      switch (color) {
        case 'White':
          colors.w = true
          break
        case 'Blue':
          colors.u = true
          break
        case 'Black':
          colors.b = true
          break
        case 'Red':
          colors.r = true
          break
        case 'Green':
          colors.g = true
          break
        case 'Colorless':
          colors.colorless = true
          break
        default:
          break
      }
    })
    args = { ...args, ...colors }
  }
  return args
}
