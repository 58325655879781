import React, { useState } from 'react'
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'

import config from 'environment'

import CategoryService from 'services/category.service'
import ToastService from 'services/toast.service'

import { store } from 'redux/store'
import { SET_ACTIVE_STATE, TOGGLE_MODAL } from 'redux/active/actions/types'
import { SET_DECK_STATE } from 'redux/deck/actions/types'
import { SET_CACHEABLES_STATE } from 'redux/cacheables/actions/types'
import { useActionless, useAppSelector } from 'redux/hooks'
import { useCanEditDeck } from 'redux/deck/selectors'
import { handleMultiSelect } from 'redux/active/actions/multiSelectors'

import { EDH_FORMATS, STACK } from 'types/deck'
import { PRICE_SOURCE } from 'types/active'
import { Option } from 'components/elements/ArchidektDropdown/types'

import ManaSymbol from 'components/misc/customIcons/ManaSymbol'
import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import ConfirmDeleteModal from 'components/elements/ConfirmDeleteModal'
import Icon from 'components/elements/Icon'

import { copyCardNamesToClipboard } from 'utils/cardsToClipboard'
import { buyFromDropdown } from 'components/formElements/BuyCardsLink'

import styles from './stackHeader.module.scss'

type Props = {
  name: string
  cardIds: string[]
  price: number
  quantity: number
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  onDragTriggerMouseUp?: () => void
  onDragTriggerMouseDown?: () => void
  extraOptions?: Option[]
  className?: string
  style?: React.CSSProperties
}

export const StackHeader = ({
  cardIds,
  name,
  price,
  quantity,
  className = '',
  dragHandleProps,
  onDragTriggerMouseDown,
  onDragTriggerMouseUp,
  extraOptions = [],
}: Props) => {
  const [toggleModal, setActiveState, setDeckState, setCacheableState] = useActionless(
    TOGGLE_MODAL,
    SET_ACTIVE_STATE,
    SET_DECK_STATE,
    SET_CACHEABLES_STATE,
  )

  const stackType = useAppSelector(state => state.deck.stack)
  const priceSource = useAppSelector(state => state.active.priceSource)
  const category = useAppSelector(state => state.deck.categories[name])
  const format = useAppSelector(state => state.deck.format)
  const hideOutOfDeckCategories = useAppSelector(state => state.cacheables.hideOutOfDeckCategories)
  const canEditDeck = useCanEditDeck()

  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false)
  const [confirmHideMaybeboard, setConfirmHideMaybeboard] = useState(false)

  const handleSelectAllIds = () => {
    const { cardMap } = store.getState().deck
    const cards = Object.values(cardMap).filter(card => cardIds.includes(card.id))

    handleMultiSelect(cards)
  }

  const handleHeaderClick = (e: React.MouseEvent) => {
    if (config.getOnMac() && e.metaKey) return handleSelectAllIds()
    if (!config.getOnMac() && e.ctrlKey) return handleSelectAllIds()
  }

  const handleCopyCardNames = (withQuantity?: boolean) => {
    const cardMap = store.getState().deck.cardMap
    const cards = cardIds.map(id => cardMap[id])

    copyCardNamesToClipboard(cards, withQuantity)
  }

  const handleDeleteCategory = () => {
    return CategoryService.remove(category.id)
      .then(() => {
        const updatedCategories = { ...store.getState().deck.categories }

        delete updatedCategories[category.name]

        setDeckState({ categories: updatedCategories })
      })
      .catch(_err => ToastService.create('Error deleting category', 'Category Service', 'error'))
      .finally(() => setConfirmDeleteCategory(false))
  }

  let visualPrice = ''
  if (priceSource[0] !== PRICE_SOURCE.MTGO && priceSource[0] !== PRICE_SOURCE.CM) visualPrice += '$'
  visualPrice += `${price.toFixed(2)}`
  if (priceSource[0] === PRICE_SOURCE.CM) visualPrice += '€'

  const isEDH = EDH_FORMATS.includes(format)
  const isManaSymbolStack = (stackType === STACK.CMC || stackType === STACK.MANA_VALUE_SPELLS) && /^\d+$/.test(name)
  const needsCategoryControls = canEditDeck && (stackType === STACK.CUSTOM || stackType === STACK.MULTIPLE)
  const showLandSuggestions = canEditDeck && name === 'Land' && isEDH

  return (
    <>
      <div className={`${styles.container} ${className}`} onClick={handleHeaderClick}>
        <div className={styles.header}>
          <h4 {...dragHandleProps} onMouseDown={onDragTriggerMouseDown} onMouseUp={onDragTriggerMouseUp}>
            {!!dragHandleProps && <Icon name="bars" />}
            {isManaSymbolStack ? <ManaSymbol symbol={name} /> : <span className={styles.title}>{name}</span>}
          </h4>
          <ArchidektDropdown
            defaultTriggerClassName={styles.optionsMenuTrigger}
            menuClassName={styles.options}
            options={[
              {
                label: 'Landbase Suggestions',
                onClick: () => setActiveState({ searchOpen: 'manabaseGenerator' }),
                manaFontIcon: 'c',
                hidden: !showLandSuggestions,
              },
              { type: 'spacer', hidden: !showLandSuggestions },
              {
                icon: 'edit',
                label: 'Edit category',
                onClick: () => toggleModal({ modalName: 'editCategory', flag: category }),
                hidden: !needsCategoryControls,
              },
              {
                icon: 'trash alternate outline',
                label: 'Delete category',
                onClick: () => setConfirmDeleteCategory(true),
                hidden: !needsCategoryControls || cardIds.length !== 0,
              },
              { type: 'spacer', hidden: !needsCategoryControls },
              { icon: 'hand point up outline', label: 'Select all', onClick: handleSelectAllIds },
              {
                label: `${hideOutOfDeckCategories ? 'Show' : 'Hide'} Maybeboard(s)`,
                onClick: () =>
                  !hideOutOfDeckCategories
                    ? setConfirmHideMaybeboard(true)
                    : setCacheableState({ hideOutOfDeckCategories: !hideOutOfDeckCategories }),
                icon: !hideOutOfDeckCategories ? 'eye slash' : 'eye',
              },
              { type: 'spacer' },
              { icon: 'copy outline', label: 'Copy card names', onClick: handleCopyCardNames },
              {
                icon: 'copy outline',
                label: 'Copy card names w/ qty',
                onClick: () => handleCopyCardNames(true),
              },
              { type: 'spacer' },
              buyFromDropdown(cardIds, priceSource[0], 'stack-header', 'Buy this stack'),
              ...extraOptions,
            ]}>
            <button className={styles.stackMenuTrigger}>
              <Icon name="ellipsis horizontal" />
            </button>
          </ArchidektDropdown>
        </div>

        <div className={styles.meta}>
          <span>Quantity: {quantity}</span>
          <span className={styles.price}>Price: {visualPrice}</span>
        </div>
      </div>

      {(stackType === STACK.CUSTOM || stackType === STACK.MULTIPLE) && (
        <ConfirmDeleteModal
          open={confirmDeleteCategory}
          onClose={() => setConfirmDeleteCategory(false)}
          onDelete={handleDeleteCategory}
          header="Delete category"
          message={`Are you sure you want to delete "${category?.name}"?`}
          buttonText={`Delete "${category?.name}"`}
        />
      )}

      <ConfirmDeleteModal
        open={confirmHideMaybeboard}
        onClose={() => setConfirmHideMaybeboard(false)}
        onConfirm={() => {
          setCacheableState({ hideOutOfDeckCategories: !hideOutOfDeckCategories })
          setConfirmHideMaybeboard(false)
        }}
        header="Hide Maybeboard(s)"
        message='Are you sure you want to hide the Maybeboard(s)? You can unhide them from any stack header or the "View as" category options.'
        buttonText="Hide all Maybeboard(s)"
      />
    </>
  )
}

export default StackHeader
