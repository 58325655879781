import React, { useEffect, useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import { useCookies } from 'react-cookie'

import { useAppSelector } from 'redux/hooks'

import DeckService from 'services/deck.service'
import RequestService from 'services/request.service'
import ToastService from 'services/toast.service'

import { CardType, defaultCard } from 'types/deck'
import { IN_DECK } from 'types/search'
import { DecksWithCardResponseType, DeckWithCardType } from 'services/apiTypes/deck.types'

import PhatButton from 'components/formElements/PhatButton'
import CardDetailsOverlay from 'components/elements/Overlay/CardDetailsOverlay'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import { getCardDefaultCategoryFromCard } from 'utils/CategoryGenerator'
import { searchParams } from 'utils/SearchParams'

import styles from './addToOtherDeck.module.scss'

type Props = {
  label?: string
  onAdded?: (deckName: string) => void
} & ({ card: CardType; cards?: never } | { card?: never; cards: CardType[] }) &
  (
    | { parentControlled: true; open: boolean; onClose: () => void }
    | { parentControlled?: false; open?: never; onClose?: never }
  )

const categoryOptions = (category: string, multiple?: boolean): Array<any> => [
  {
    key: multiple ? 'Default' : category,
    text: multiple ? 'Default Category' : category,
    value: multiple ? false : category,
  },
  { key: 'Sideboard', text: 'Sideboard', value: 'Sideboard' },
  { key: 'Maybeboard', text: 'Maybeboard', value: 'Maybeboard' },
]

const otherDecksOptions = (decks: Array<DeckWithCardType>): Array<Record<string, any>> => {
  return decks.map((deck, i) => {
    let text = deck.name
    if (deck.hasCard === IN_DECK.NAME_IN_DECK) text = `${deck.name} (Different Edition)`
    else if (deck.hasCard === IN_DECK.EXACT_IN_DECK) text = `${deck.name} (In Deck)`

    return {
      key: i,
      value: deck.id,
      text,
    }
  })
}

const AddToOtherDeck = ({ card, cards, label, parentControlled = false, onAdded, open: parentOpen }: Props) => {
  const [{ tbId: userId }] = useCookies(['tbId'])

  const currentDeckId = useAppSelector(state => state.deck.id)

  const selectedCards = card ? [card] : cards

  const [open, setOpen] = useState(parentOpen || false)

  useEffect(() => {
    if (!parentControlled) return

    setOpen(parentOpen || false)
  }, [parentOpen, parentControlled])

  useEffect(() => {
    if (!open) return

    setDecksWithCard([])
  }, [open])

  // prettier-ignore
  const [selectedCategory, setSelectedCategory] = useState(selectedCards.length > 1 ? null : getCardDefaultCategoryFromCard(selectedCards[0]))
  const [selectedDeckId, setSelectedDeckId] = useState(null)
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [decksWithCard, setDecksWithCard] = useState<Array<DeckWithCardType>>([])
  const [addLoading, setAddLoading] = useState(false)

  const fetchDropdownData = async () => {
    setDropdownLoading(true)

    const requestUrl = `/api/users/${userId}/decksWithCard/${
      !card ? '' : searchParams({ id: card.id, name: card.name })
    }`

    RequestService.get<DecksWithCardResponseType>(requestUrl)
      .then(data => setDecksWithCard(data.decks))
      .finally(() => setDropdownLoading(false))
  }

  const handleDropdownOpen = () => {
    if (!dropdownLoading && !decksWithCard.length) fetchDropdownData()
  }

  const handleCategoryChange = (_event: any, { value }: Record<string, any>) => setSelectedCategory(value)
  const handleDeckChange = (_event: any, { value }: Record<string, any>) => setSelectedDeckId(value)

  const handleAddToOtherDeck = async () => {
    const deck = decksWithCard.find(obj => obj.id === selectedDeckId)

    if (deck && deck.hasCard === IN_DECK.NOT_IN_DECK) {
      setAddLoading(true)

      DeckService.add(
        deck.id,
        selectedCards.map((card, index) => {
          const defaultCategory = getCardDefaultCategoryFromCard(card)
          const categories = selectedCategory !== 'Default' ? [selectedCategory || defaultCategory] : [defaultCategory]

          return {
            ...card,
            qty: card.qty || 1,
            modifier: card.modifier || card.options[0],
            categories: categories,
          }
        }),
      )
        .then(() => {
          if (!!card) ToastService.create(`${card?.name} added to ${deck.name}`, 'Deck service', 'success')
          if (selectedCards.length > 1)
            ToastService.create(`${selectedCards.length} cards added to ${deck.name}`, 'Deck service', 'success')

          setOpen(false)
        })
        .catch(err => RequestService.createToast(err))
        .finally(() => {
          setAddLoading(false)
          fetchDropdownData()

          if (onAdded) onAdded(deck.name)
        })
    }
  }

  const defaultCategory = getCardDefaultCategoryFromCard(selectedCards[0])
  const deckList = decksWithCard.filter(obj => obj.id !== currentDeckId)
  const currentlySelectedDeck = decksWithCard.find(obj => obj.id === selectedDeckId)

  return (
    <>
      {!parentControlled && (
        <PhatButton onClick={() => setOpen(true)}>
          <Icon name="plus" icon="folder" /> {label ? label : 'Add to other deck'}
        </PhatButton>
      )}

      <CardDetailsOverlay
        rightControls={null}
        tabs={[
          {
            id: 'add-to-other-deck',
            label: 'Add to other deck',
            body: (
              <div className={styles.container}>
                {card?.name && <h3>Add {card?.name} to other deck</h3>}
                {selectedCards.length > 1 && <h3>Add {selectedCards.length} card(s) to other deck</h3>}

                <Dropdown
                  fluid
                  selection
                  name="deckOptions"
                  placeholder="Select a deck..."
                  loading={dropdownLoading}
                  onOpen={handleDropdownOpen}
                  options={otherDecksOptions(deckList)}
                  onChange={handleDeckChange}
                />

                <Dropdown
                  fluid
                  selection
                  name="categoryOptions"
                  options={categoryOptions(defaultCategory, selectedCards.length > 1)}
                  defaultValue={defaultCategory}
                  onChange={handleCategoryChange}
                  disabled={!currentlySelectedDeck || currentlySelectedDeck.hasCard !== IN_DECK.NOT_IN_DECK}
                />

                <div className={styles.controls}>
                  <button
                    disabled={
                      !currentlySelectedDeck || currentlySelectedDeck.hasCard !== IN_DECK.NOT_IN_DECK || addLoading
                    }
                    onClick={handleAddToOtherDeck}>
                    {!addLoading && 'Add Card(s)'}
                    {addLoading && (
                      <>
                        <SimpleSpinner size="medXSmall" /> Adding card(s)...
                      </>
                    )}
                  </button>
                </div>
              </div>
            ),
          },
        ]}
        onTabChange={() => null}
        activeTabId="add-to-other-deck"
        card={card || defaultCard}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default AddToOtherDeck
